<template>
  <div>
    <b-modal
      id="modal-1"
      :title="msgModaleTitle"
      ref="msg-modal"
      ok-only
      :no-close-on-backdrop="true"
    >
      <p class="my-4">{{ msg }}</p>
    </b-modal>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :search="search"
      class="elevation-1"
      style="font-family: Poppins, Helvetica: sans-serif"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Templates</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            @click="editItem()"
            >New Template</v-btn
          >
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Template Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      Template Name
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{ editedItem.tpl_name }}
                    </v-col>
                    <v-col cols="12" sm="4">
                      Template Display Name
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{ editedItem.tpl_disp_name }}
                    </v-col>
                    <v-col cols="12" sm="4">
                      Questionnaire
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{ editedItem.questionnaire }}
                    </v-col>
                    <v-col cols="12" sm="4">
                      Publish Status
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{ editedItem.publish_status }}
                    </v-col>
                    <!--<v-col cols="12" sm="4">
                      Template Version
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{ editedItem.tpl_version }}
                    </v-col>-->
                    <v-col cols="12" sm="4">
                      Regulations
                    </v-col>
                    <v-col cols="12" sm="8">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="regulation in editedItem.regulations"
                          v-bind:key="regulation"
                        >
                          {{ regulation }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="4">
                      Sections
                    </v-col>
                    <v-col cols="12" sm="8">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="section in editedItem.sections"
                          v-bind:key="section"
                        >
                          {{ section }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="4">
                      Custom Fields
                    </v-col>
                    <v-col cols="12" sm="8">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="customField in editedItem.custom_fields"
                          v-bind:key="customField"
                        >
                          {{ customField }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-b-tooltip.hover.bottom="'View Template Details'"
          small
          class="mr-2"
          @click="viewItem(item)"
        >
          info
        </v-icon>
        <v-icon
          v-b-tooltip.hover.bottom="'View Template Questions'"
          small
          class="mr-2"
          @click="viewTemplateQuestions(item)"
        >
          mdi-view-list
        </v-icon>
        <v-icon
          v-if="item.publish_status != 'Published'"
          v-b-tooltip.hover.bottom="'Edit Template Details'"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="item.publish_status == 'Published'"
          v-b-tooltip.hover.bottom="'Clone Template'"
          small
          class="mr-2"
          @click="cloneTemplate(item)"
        >
          mdi-source-fork
        </v-icon>
        <v-icon
          v-b-tooltip.hover.bottom="'Delete Template'"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import CCApiService from "@/core/services/aws.ccapi.service";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [],
      tableData: [],
      loading: false,
      editedItem: {
        tpl_id: 0,
        tpl_name: "",
        tpl_disp_name: "",
        questionnaire: "",
        tpl_version: "",
        publish_status: "",
        help_text: "",
        regulation: "",
        customField: "",
        section: ""
      }
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  mounted() {},
  methods: {
    initialize() {
      this.headers = [
        {
          text: "#",
          align: "left",
          value: "tpl_id",
          width: "5%"
        },
        { text: "Template Name", value: "tpl_name", width: "25%" },
        { text: "Template Display Name", value: "tpl_disp_name", width: "25%" },
        //{ text: "Version", value: "tpl_version", width: "5%", align: "d-none" },
        { text: "Questionnaire", value: "questionnaire", width: "15%" },
        { text: "Publish Status", value: "publish_status", width: "10%" },
        { text: "Actions", value: "actions", sortable: false, width: "15%" }
      ];
      this.getTemplates();
    },

    editItem(item) {
      var tpl_id =
        item != undefined && item.hasOwnProperty("tpl_id") && item.tpl_id > 0
          ? item.tpl_id
          : 0;

      localStorage.setItem("tpl_id", tpl_id);
      this.$router.push({ path: "generate-template/" + tpl_id });
    },

    viewTemplateQuestions(item) {
      var tpl_id =
        item != undefined && item.hasOwnProperty("tpl_id") && item.tpl_id > 0
          ? item.tpl_id
          : 0;

      localStorage.setItem("tpl_id", tpl_id);
      this.$router.push({ path: "template-question-list/" + tpl_id });
    },

    cloneTemplate(item) {
      if (item == undefined || item.tpl_id == undefined) {
        return;
      }
      const apiName = "OneAudit";
      const path = "/templates/clone";

      const params = {
        id: item.tpl_id,
        todo: "clone"
      };

      CCApiService.post(apiName, path, params)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") &&
                response.data.hasOwnProperty("data_updated") == true &&
                response.data.data_updated == "true" &&
                response.data.hasOwnProperty("id") == true &&
                response.data.id > 0
              ) {
                this.tpl_id = response.data.id;
                localStorage.setItem("tpl_id", this.tpl_id);
                this.$router.push({ path: "generate-template/" + this.tpl_id });
              } else {
                this.showMsg("error", "Failed to add template");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          if (
            error.hasOwnProperty("response") == true &&
            error.response != undefined
          ) {
            if (
              error.response.hasOwnProperty("data") == true &&
              error.response.data != undefined
            ) {
              if (
                error.response.data.hasOwnProperty("message") == true &&
                error.response.data.message != undefined
              ) {
                this.showMsg("error", error.response.data.message);
              } else {
                this.showMsg("error", error.response.data);
              }
            } else {
              this.showMsg("error", error.response);
            }
          } else {
            this.showMsg("error", error);
          }
        });
    },

    viewItem(item) {
      if (item == undefined || item.tpl_id == undefined) {
        return;
      }

      const apiName = "OneAudit";
      const path = "/templates/" + item.tpl_id;
      CCApiService.get(apiName, path)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {});
    },

    deleteItem(item) {
      const id =
        item != undefined && item.hasOwnProperty("tpl_id") && item.tpl_id > 0
          ? item.tpl_id
          : 0;
      if (id > 0 && confirm("Are you sure you want to delete this item?")) {
        const apiName = "OneAudit";
        const path = "/templates/" + id;
        CCApiService.delete(apiName, path)
          .then(() => {
            this.initialize();
            this.showMsg("success", "Record successfully deleted");
          })
          .catch(error => {
            this.showMsg("error", error);
          });
      }
    },

    getTemplates() {
      const apiName = "OneAudit";
      const path = "/templates";
      this.loading = true;
      CCApiService.get(apiName, path)
        .then(response => {
          this.tableData = [];

          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                for (var index in response) {
                  var sectionNames = [];
                  var regulationNames = [];
                  var customFieldNames = [];

                  if (response[index].regulations != undefined) {
                    for (var i in response[index].regulations) {
                      regulationNames.push(
                        response[index].regulations[i].regln_name
                      );
                    }
                  }

                  if (response[index].sections != undefined) {
                    for (var j in response[index].sections) {
                      sectionNames.push(response[index].sections[j].sect_name);
                    }
                  }

                  if (response[index].custom_fields != undefined) {
                    for (var k in response[index].custom_fields) {
                      customFieldNames.push(
                        response[index].custom_fields[k].field_name
                      );
                    }
                  }

                  if (response[index].tpl_id == undefined) {
                    break;
                  }

                  this.tableData.push({
                    tpl_id: response[index].tpl_id,
                    tpl_name: response[index].tpl_name,
                    questionnaire: response[index].questionnaire.name,
                    tpl_disp_name: response[index].tpl_disp_name,
                    tpl_version: response[index].tpl_version,
                    publish_status: response[index].publish_status,
                    regulations: regulationNames,
                    sections: sectionNames,
                    custom_fields: customFieldNames
                  });
                }
              } else {
                //this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showMsg: function(type, msg) {
      this.msgModaleTitle = type == "error" ? "Error" : "Message";
      this.msg = msg;
      this.$refs["msg-modal"].show();
    }
  }
};
</script>
